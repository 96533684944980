<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, selecione as bases que serão utilizadas na análise
    </div>
    <v-row
      id="selecao-bases-geograficas"
      class="mx-auto"
    >
      <v-col cols="12">
        <bases-geograficas
          :bases="basesLiberadas"
          :basesSelecionadasUpdate="items.basesGeograficas"
          @selecaoBasesAtualizadas="basesSelecionadasAtualizadas"
        />
      </v-col>
    </v-row>
    <div v-if="camposValoresComuns.length">
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione o campo que será utilizado e aplique uma função para o cálculo
      </div>
      <v-row
        id="selecao-campo"
        class="mx-auto"
      >
        <v-col cols="12">
          <campo-calculo
            :campos="camposValoresComuns"
            :campoSelecionado="campoSelecionadoCalculo"
            @campoCalculoSelecionado="setCampoCalculoSelecionado"
          />
        </v-col>
      </v-row>
    </div>
    <div v-if="camposFiltros.length">
      <div class="text-center display-1 font-weight-normal mb-6">
        Opcionalmente, adicione filtros em sua análise
      </div>
      <v-row
        id="selecao-filtros"
        class="mx-auto"
      >
        <v-col cols="12">
          <campos-filtros
            :month="month"
            :bdgdVersion="bdgdVersion"
            :campos="camposFiltros"
            :baseDados="primeiraViewSelecionada"
            :filtrosSelecionados="filtrosSelecionados"
            @filtrosAtualizados="setFiltros"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BasesDadosService from '@/services/BasesDadosService';
import { intersection, indexBy } from 'underscore';

export default {
  name: 'SecondTabContent',

  components: {
    BasesGeograficas: () =>
      import('@/components/analises-setorizadas/BasesGeograficas'),
    CampoCalculo: () =>
      import('@/components/analises-setorizadas/CampoCalculo'),
    CamposFiltros: () =>
      import('@/components/analises-setorizadas/CamposFiltros')
  },

  props: {
    month: {
      type: String
    },
    bdgdVersion: {
      type: Number
    },
    items: {
      type: Object
    },
    campoSelecionadoCalculo: {
      type: Object
    },
    filtrosSelecionados: {
      type: Array
    }
  },

  data() {
    return {
      basesLiberadas: [],
      basesSelecionadas: [],
      basesLiberadasCampos: {},
      basesLiberadasCamposUpdate: {},
      campoCalculoSelecionado: {
        campo: '',
        funcao: 'SOMAR'
      },
      tiposCamposValores: ['numeric'],
      forcarComoCampoValor: ['contador'],
      filtros: {},
      campoSelecionado: {},
      filtrosEdit: []
    };
  },

  mounted() {
    this.getBasesLiberadas();
  },

  computed: {
    camposClassificados() {
      let camposClassificados = {};

      let basesLiberadasCampos =
        !this.basesLiberadasCampos.length && this.items.id != null
          ? typeof this.items.basesLiberadasCampos === 'string'
            ? JSON.parse(this.items.basesLiberadasCampos)
            : this.items.basesLiberadasCampos
          : this.basesLiberadasCampos;

      Object.keys(basesLiberadasCampos).forEach((base) => {
        const camposFiltros = basesLiberadasCampos[base];
        const camposFiltrosList = camposFiltros.map((campo) => campo.column);
        const camposFiltrosTiposDados = indexBy(camposFiltros, 'column');

        const camposValores = camposFiltros.filter(
          (campo) =>
            this.tiposCamposValores.includes(campo.type) ||
            this.forcarComoCampoValor.includes(campo.column)
        );
        const camposValoresList = camposValores.map((campo) => campo.column);

        camposClassificados[base] = {
          camposFiltros,
          camposFiltrosList,
          camposFiltrosTiposDados,
          camposValores,
          camposValoresList
        };
      });

      this.basesLiberadasCamposUpdate = basesLiberadasCampos;

      return camposClassificados;
    },

    camposValoresComuns() {
      let campos = [];

      this.basesSelecionadas.forEach((base) => {
        campos.push(this.camposClassificados[base.descricao].camposValoresList);
      });

      return intersection(...campos);
    },

    camposFiltrosComuns() {
      let campos = [];

      this.basesSelecionadas.forEach((base) => {
        campos.push(this.camposClassificados[base.descricao].camposFiltrosList);
      });

      return intersection(...campos);
    },

    camposFiltros() {
      let camposFiltros = [];

      if (
        this.primeiraBaseSelecionada &&
        this.camposClassificados[this.primeiraBaseSelecionada]
      ) {
        let camposFiltrosTiposDados =
          this.camposClassificados[this.primeiraBaseSelecionada]
            .camposFiltrosTiposDados;
        camposFiltros = this.camposFiltrosComuns.map(
          (campo) => camposFiltrosTiposDados[campo]
        );
      }

      return camposFiltros.filter(
        (campo) =>
          ![
            'company',
            'company_id',
            'contador',
            'geom',
            'bdgd_versao_id'
          ].includes(campo.column)
      );
    },

    basesSelecionadasList() {
      return this.basesSelecionadas.map((base) => base.descricao);
    },

    primeiraBaseSelecionada() {
      return this.basesSelecionadas.length
        ? this.basesSelecionadas[0].descricao
        : null;
    },

    viewsSelecionadasList() {
      return this.basesSelecionadas.map((base) => base.nome_tabela);
    },

    primeiraViewSelecionada() {
      return this.basesSelecionadas.length
        ? this.basesSelecionadas[0].nome_tabela
        : null;
    }
  },

  methods: {
    getBasesLiberadas() {
      BasesDadosService.getBasesDadosLiberadasAnalisesSetorizadas().then(
        (response) => {
          this.basesLiberadas = Object.values(response.data);
        }
      );
    },

    getBasesLiberadasCampos() {
      this.basesLiberadas.forEach(async (base) => {
        const item = await BasesDadosService.getBasesDadosCampos(
          base.nome_tabela
        );
        this.basesLiberadasCampos[base.descricao] = item;
      });
    },

    basesSelecionadasAtualizadas(basesSelecionadas) {
      this.basesSelecionadas = basesSelecionadas;
    },

    setCampoCalculoSelecionado(campoCalculoSelecionado) {
      this.campoCalculoSelecionado = campoCalculoSelecionado;
    },

    setFiltros(filtros) {
      this.filtros = filtros;
    },

    emitEventDataUpdated() {
      this.$emit('data:updated', {
        basesSelecionadas: this.basesSelecionadas,
        campoCalculoSelecionado: this.campoCalculoSelecionado,
        filtros: this.filtros
      });
    }
  },

  watch: {
    basesLiberadas() {
      this.getBasesLiberadasCampos();
    },

    basesLiberadasCamposUpdate() {
      this.$emit('data:updated', {
        basesLiberadasCampos: this.basesLiberadasCamposUpdate
      });
    },

    basesSelecionadas() {
      this.emitEventDataUpdated();
    },

    campoCalculoSelecionado() {
      this.emitEventDataUpdated();
    },

    filtros() {
      this.emitEventDataUpdated();
    }
  }
};
</script>
